@import 'Components/global.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(252, 252, 252);
  border-color: rgb(242, 242, 242);
  background-image: linear-gradient(to top, #ebeaea 0%, #f8f8f8 100%);
  border-width: 1px;
  border-style: solid;
  padding: 0px;

  .header {
    font-family: $proxima;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #707070;
    margin-top: 30px;
  }

  .assignButton {
    width: 151px;
    height: 47px;
    left: 97px;
    top: 100px;
    background: #F4F4F4;
    border: 4px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    margin: 8px auto 25px;
    font-family: $proxima;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: block;
    align-items: center;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    text-align: center;
    color: #D0D0D0;
  }
}
