@import 'Components/global.scss';


.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  background-image: url("../../../../Assets/confetti_large.svg");
  height: 341px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.checkTitle {
  display: flex;
  justify-content: center;
}


.header > div:first-of-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.confirmCheck {
  height: 44px;
  width: 44px;
  background-color: #29b573;
  margin-right: 25px;
  border-radius: 4px;
}

.confirmCheck i {
  font-size: 44px;
  line-height: 44px;
  width: 100%;
  color: #ffffff;
  text-align: center;
}

.header h1 {
  color: $navy-blue;
  font-family: $proxima;
  font-size: 36px;
  font-weight: 700;
  margin: 0;
}

.headerContainer {
  background-color: #ffffff;
  max-width: 60%;
  margin: 4em auto 0;
  display: flex;
  justify-content: center;
  padding: 1em;
}

.headerContainer p {
  font-family: $proxima;
  color: $navy-blue;
}

.mediumInstructions {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0.5em;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}


.smallInstructions {
  font-size: 14px;
  font-weight: 600;
  margin: 0.2em;
  letter-spacing: 0em;
  margin: 0em;
  text-align: center;
}

.alignLeft {
  text-align: left;
  margin: 0.5em 0 0.5em 0;
}

.header b {
  font-weight: 600;
}

.confetti {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.confetti > svg {
  height: 73px;
  width: 150px;
  margin-bottom: 25px;
}

.linksContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  // margin: 30px auto;
}

.marginTop {
  margin-top: 3em;
}

.linkBox {
  width: 560px;
  height: 252px;
  background-color:#F7F9FD;
  margin: 1em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2em;
}

.linkBoxWide {
  width: 860px;
  height: 252px;
  background-color:#F7F9FD;
  margin: 1em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2em;
}

.linkBoxTextContainer {
  margin-left: 1em;
  h2 {
    color: $navy-blue;
    font-family: $proxima;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 0.5em 0;
  }
  p {
    font-family: $proxima;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #2D2D2D;
    ;
  }
}

.exploreDashboard {
  font-family: $proxima;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.035em;
  color: #3097D6;
}

.inlineIcon {
  vertical-align: bottom;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 22px;
}

.inviteButtons {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.laterButton {
  height: 62px;
  flex: 0 0 calc(50% - 5px);
  border-radius: 8px;
  border: 1px solid #48A3DB;
  background-color: #ffffff;
  color: #48A3DB;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.infoIcon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: -7px;
  right: -10px;
  border-radius: 50%;
  border: 1px solid #3564B9;
  cursor: pointer;
}