.mainComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 40px;
  position: relative;
  background: #ffffff;


  .innerDivOne {
    .innerDivTwo {
      display: flex;
      flex-direction: column;
      align-items: center;

      .medicalPlanHeader {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.035em;
        text-transform: uppercase;
        color: #16346F;
      }

      .ageBandedRates {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 7px;
        line-height: 115%;
        display: flex;
        align-self: flex-start;
        letter-spacing: 0.035em;
        text-transform: uppercase;
        color: #16346F;
      }
    }
  }
}