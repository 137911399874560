@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,200;0,400;0,500;0,600;1,300;1,400&display=swap");

.head {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: none;
  padding-bottom: 24px;
}
.clientDisclaimer {
  font-style: italic;
  font-weight: normal;
  width: 1222px;
  background: #f4f4f4;
  border: 1px solid #ebebeb;
  margin: 8px 0px;
  padding: 13px 40px 13px 15px;
}
.discTxt {
  font-family: "Barlow Semi Condensed", sans-serif !important;
  font-size: 14px;
  line-height: 17px;
}

.head > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.reporting_container {
  width: 470px;
  margin-top: 40px;
}
.reporting_inner_cont {
  background: #fcfcfc;
  border: 2px solid #f0f8fc;
}
.innerReport {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 8px;
  font-family: "Barlow Semi Condensed", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.innerSideLabel {
  font-family: "Barlow Semi Condensed", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.repName {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: #fcfcfc;
  border-bottom: 2px solid #f0f8fc;
  padding: 15px 10px 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}
