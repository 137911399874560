@import './Plan.module.scss';

.pbContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
  flex: 0 0 370px;
  margin-right: 0;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.premiums { flex: 0 0 250px }

  }
}

.sectionTitle {
  font-size: .875rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .05rem;
}

.erCost {
  color: navy-blue();
  font-size: 2.75rem;
  line-height: 2rem;
  margin-top: 1.5rem;
  font-weight: 600;

  span:first-of-type {
    display: inline-block;
    vertical-align: top;
    font-size: 1.5rem;
    line-height: 1.25rem;
  }

  span:last-of-type { font-size: 1.5rem }
}

.perEmployee {
  font-size: .75rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.08rem;
  color: navy-blue();
}

.premiums {
  padding: unset;
  margin-top: $box-margin;
  height: 100%;
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    height: 25%;
    &:nth-child(even) { background-color: blue(30);
    > div.Supplemental_tier{
      color: #16346F;
    }
    }
  }
}

.noTopMargin {
  margin-top: 0px;
}

.tier {
  font-size: .75rem;
  font-weight: 700;
  letter-spacing: .02rem;
  text-align: left;
  margin-left: $box-margin;
  color: #3564B9;

  > div:last-child {
    font-size: .5rem;
    margin-top: .125rem;
    text-transform: uppercase;
  }
}
.Supplemental_tier{
  font-weight: 600;
  font-size: 16px;
  > div:last-child{
    font-weight: 600;
    font-size: 12px;
  }
}
.tierAmounts{
  >span:first-child{
  font-weight: 600;
    font-size: 14px;
  }
}
.amount{
  font-size: 1.5rem;
  color: white;
  background-color: transparentize($color: periwinkle(90), $amount: .5);
  height: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem $box-margin;
  margin-bottom: 0;

  span {
    font-size: .75rem;

    &:first-child {
      display: inline-block;
      vertical-align: top;
      line-height: 1.25rem;
      margin-right: .075rem;
    }
  }
}

.dollar{
  margin-top: 4px;
  font-size: 14px;
  font-weight: 600;
}

.flexStart {
  align-self: flex-start;
}

.fourSpecs.benifitContainer {
  flex: 0 0 225px;
}
.fourSpecs {
  padding-right: 0.625rem;
}

.fourSpecTableSpan {
  color: #16346f;
  font-weight: 500 !important;
  vertical-align: middle;
  margin: 0;
}

.blueHeader {
  font-weight: 700;
  background-Color: #3564b9;
  font-size: 0.875rem;
  color: white;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0 1rem;
}

.vtlBox {
  border-radius: 0 0 $corner-radius $corner-radius;
  background-color: #ffffff;
  padding: 15px;
  overflow: hidden;
  margin-bottom: $box-margin;
}

.rateGrid {
  height: 100%;
  display: grid;
  grid-template-columns: 212px auto;
  grid-template-rows: repeat(2, 1fr);
  div {
    padding: 0.75em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .nonSmoker {
    background-color: #EDF6FB;
    border-right: 1px solid #EAEAEA;
    div {
      font-size: 14px;
      font-weight: 700;
      color: $navy-blue;
      &:after {
        content: "*";
        color: #FF9A7A;
      }
    }
  }
  .nonSmokerPrice {
    background-color: #EDF6FB;
  }
  .possible {
    background-color: #FFFFFF;
    border-right: 1px solid #EAEAEA;
    border-radius: 0 0 0 $corner-radius;
    div {
      font-size: 14px;
      font-weight: 700;
      color: $navy-blue;
      &:after {
        content: "*";
        color: #FF9A7A;
      }
    }
  }
  .possiblePrice {
    background-color: #FFFFFF;
    border-radius: 0 0 $corner-radius 0;
  }

  .nonSmokerPrice, .possiblePrice {
    span {
      color: $navy-blue;
      &:nth-of-type(1) {
        display: inline-block;
        vertical-align: top;
        line-height: 1.25rem;
        margin-right: 0.075rem;
        padding-bottom: 10px;
        font-weight: 600;
      }
      &:nth-of-type(2) {
        font-size: 2rem;
        font-weight: 600;
        display: inline-block;
        line-height: 1.25rem;
        margin-right: 0.075rem;
      }
      &:nth-of-type(3) {
        font-size: 1.2rem;
        font-weight: 700;
        vertical-align: bottom;
        padding-top: 5px;
      }
    }
  }
}

.vtlHeight {
  height: calc(100% - 32px);
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}