@import 'Components/global.scss';

.mainContainer {
  @include default-content-sizing;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-weight: 700;
    text-align: left;
    margin: 2rem 0;

  }

  > button {
    margin-top: 1rem;
    float: right;

    &.imqButton { background-color: #5C90D5 }
  }
}
article {
  width: 100%;
    margin: 0 auto;
    .planTitle{
     text-align: left;
     padding: 15px 0 10px 2px;
   }
}
.supplemental_lines_with_btn{
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin: unset;
  }
}

.supplementalHeading {
  width: 1052px;
  margin: 0 auto;

  ul {
    margin: 0;
    padding: 4px 0;
    padding-left: 13px;
    width: 800px;
  }

  h1 {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 0;
  }

  .supplementalHeading {
    font-size: 18px;
    font-weight: 600;

  }
}

.proposal_div {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: #EDF6FB;
  margin-top: -30px;

  p {
    font-family: $zilla;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    align-items: center;
    letter-spacing: 0.02em;
    color: #16346F;
  }

  button {
    padding: 17px 28px;
    gap: 10px;
    width: 255px;
    height: 56px;
    background: #48A3DB;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    border-radius: 6px;
    color: #FFFFFF;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

.bold {
  font-weight: bold;
}

.mq_info_div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    flex-direction: column;
    color: #16346F;
    width: 921px;

    span {
      text-align: left;
      width: 100%;
      padding-bottom: 10px;
    }
  }
}

.warning {
  background: concern(60);
  color: concern(180)!important;
  border-color: concern(140);
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 10px 10px 10px 50px;
  width: 921px;
  position: relative;

  &:before{
    content: "!";
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    background-color: concern(150);
    color: concern(0);
    font-weight: bold;
  }
  b {
    display: block;
    color: concern(160);
  }
}

.contributionModeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: blue(10);
  padding: 1rem 1.5rem;
  color: navy-blue();
  margin-bottom: 2rem;

  > * { margin-bottom: 1.5rem; }

  p {
    font-size: 1.25rem;
    font-family: $zilla;
    text-align: center;
    letter-spacing: .015em;
    font-weight: 600;
  }

  input {
    text-align: center;
    width: 200px;
    margin-right: 0;
  }
}

.contributionModeSwitch {
  height: 58px !important;
  width: 200px !important;
  border-radius: 8px !important;
  border: solid 1px gray() !important;

  button[data-state=unselected] {
    background-color: white !important;
  }
}

.contributionContainer {
  background-color: gray(5);
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr 440px;
  color: navy-blue();
  margin-bottom: 2rem;

  p {
    font-family: $zilla;
    padding-left: 1.25rem;
    border-left: 4px solid periwinkle(30);
    margin-right: 5rem;

    span { font-weight: 700 }
  }
}

.allAncillarySection {
  background: green(25);
  padding: 3rem;
  margin-bottom: 1rem;
}

.aa_cart {
  background-color: white;
  padding: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  border-radius: 4px;

  h2 {
    font-size: 1.5rem;
    font-family: $proxima;
    color: navy-blue();
    margin: 0;
  }

  p {
    font-family: $zilla;

    &.disclaimer {
      font-size: .75rem;
      font-style: italic;
    }

    &.remaining {
      color: periwinkle();
      font-weight: 600;
      font-style: italic;
      margin-bottom: .5em;
    }
  }

  .amount {

    font-size: 3rem;
    font-weight: 600;

    &.ee { color: periwinkle() }
    &.er { color: green() }

    span {
      font-size: .5em;
      font-weight: 700;
      line-height: 1.8em;

      &:first-child {
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}

.planTypeInfo {
  font-size: 1rem;
  color: navy-blue();
  background: gray(5);
  padding: 1.25rem;
  margin-bottom: 2rem;

  p {
    font-family: $proxima;
    padding: 0 0 0 1.25rem;
    border-left: 4px solid periwinkle(30);
  }
}
.noPlansText {
  font-family: $zilla;
  font-style: italic;
  font-weight: 600;
  font-size: 2.5em;
  line-height: 140%;
  color: $navy-blue;
  text-align: center;
}
.noPlans {
  margin: 1em auto 2em;
  width: 40%;
  .noPlansImage {
    margin: auto;
    width: 100%;
  }
}

.buttonMargin {
  margin-top: 2em;
  .shop_next_button {
    padding: 0 20px;
  }
}
