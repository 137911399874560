@import 'Components/global.scss';
@import 'Components/Stargate/ShowPlans/Components/ShowPlansHeader.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');

.planSelect{
  margin-bottom: 40px;
  width: 331px;
  border-left: 4px solid #CFFFF3;
  padding-left: 20px;
  p{
    font-family: 'Zilla Slab', serif;
    color: $navy-blue;
    font-weight: 600;
    font-size: 16px;
    margin: 0;

  }
}
.mainContainer {
  max-width: $container-max-width;
  min-width: $container-min-width;
  width: 100%;
  margin: 0 auto;
  padding: 1rem $container-padding-horizontal 0;

  .hideProposalContent {
    position: absolute;

    .helperDiv {
      position: absolute;
      width: 1000px;
      height: 10000px;
      z-index: -1;
      background-color: white;
    }

    .sronly {
      position: absolute;
      z-index: -2;
    }
  }

  .plansAndFilter {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 40px;
  }
  .compare {
    position: fixed;
    display: block;
    bottom: 1.25rem;
    width: 200px;
    height: 53px;
    border-radius: 6px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: $navy-blue;
    z-index: 2;
    color: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
  }

  .plansListContainer {
    overflow: visible;
    width: 952px;
    max-width: 972px;
    margin: 0 auto;

    h2, h3 {
      position: relative;
      font-family: $zilla;
      color: $navy-blue;
      margin: 0;
      overflow: visible;
      padding-left: 30px;
    }

    h2 {
      font-size: 1rem;
      text-align: left;
      &:before {
        content: '';
        height: 2.5em;
        background-color: $mint;
        width: 5px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    h3 { font-size: 1rem; }

    .erSection {

      margin-bottom: 1.5em;
    }

    .contributionsContainer {
      background-color: #FAFAFA;
      margin-top: 1em;
      padding: 2em 0;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      h4 {
        font-family: $zilla;
        font-size: 20px;
        font-weight: 700;
        color: $navy-blue;
      }
      display: block;
      margin-bottom: 2rem;
      align-items: center;

      h2 {
        font-size: 1rem;
        text-align: left;
        &:before {
          content: '';
          height: 45px;
          background-color: $mint;
          width: 5px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .contributionsContainer {
      background-color: #FAFAFA;
      margin-top: 1em;
      padding: 2em 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      h4 {
        font-family: $zilla;
        font-size: 20px;
        font-weight: 700;
        color: $navy-blue;
      }
    }

    .eeSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: $shop-top-spacing;
      background-color: rgba( $sky-blue, .3 );
      padding: 20px 20px 20px 0 ;

      a {
        display: block;
        height: 35px;
        width: 135px;
        text-align: center;
        line-height: 35px;
        border-radius: 4px;
        font-size: .75rem;
        background-color: $sky-blue;
        color: $navy-blue;
        cursor: pointer;
        text-decoration: none;

        &:link, &:visited, &:hover, &:active { color: $navy-blue }
      }
    }
  }
}

.pipeline {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 2rem;
  margin-bottom: 1rem;

  div {
    display: flex;
    flex-direction: column;
    font-size: 3.125rem;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: navy-blue();
    padding: 2rem;

    span {
      font-size: .75rem;
      text-transform: uppercase;
      display: block;
      font-weight: 700;
      letter-spacing: .08em;
      text-align: center;
    }
  }

  .notStarted {
    background: linear-gradient(#595959 0%, #3C3C3C 100%);
    color: white;
  }
  .incomplete { background: linear-gradient(#EFEEFA 0%, #D0CDF1 100%) }
  .awaitingSignature { background: linear-gradient(#fffae7 0%, #efe6bc 100%) }
  .completed { background: linear-gradient(#E7FFF9 0%, #BCEFE2 100%) }
  .waived { background: linear-gradient(#DAEDF8 0%, #B6DAF1 100%) }
}

.remindAll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: blue(10);
  padding: 1.5rem;
  margin: 2rem 0;

  p {
    font-family: $zilla;
    color: navy-blue();
    font-weight: 600;
    border-left: 4px solid periwinkle(30);
    padding-left: 1rem;
    margin: 0;
  }

  button {
    flex: 0 0 192px;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    height: 56px;
    border-radius: 6px;
    background: periwinkle();
  }
}

.imqRow {
  display: grid;
  grid-template-columns: 1fr 130px 150px;
  gap: 1.5rem;
  align-items: center;
  padding: 1.5rem;
  background: #FAFAFA;
  margin-bottom: 2px;
  border-radius: 4px;

  button {
    border-radius: 4px;
    background: periwinkle();
    height: 34px;
    color: white;
    font-size: .75rem;
  }
}

.name {
  color: navy-blue();
  font-weight: 600;
  font-size: 1rem;
}

.status {
  color: gray(140);
  font-size: .75rem;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 17px;

  &.notStarted { background: gray(30) }
  &.incomplete { background: purple(20) }
  &.awaitingSignature { background: #efe6bc }
  &.completed { background: #D3F8EE }
  &.waived { background: blue(20) }
}

.contributionModeSwitch {
  height: 58px !important;
  width: 352px !important;
  border-radius: 8px !important;
  border: solid 1px gray() !important;

  button[data-state=unselected] {
    background-color: white !important;
  }
}

.disclaimer {
  font-style: italic;
  color: $navy-blue;
  font-weight: 400;
  font-size: 14px;
}

.fixedFilter {
  position: sticky;
  top: 150px;
  overflow-y: scroll;
  max-height: calc(100vh - 355px);
  @include scrollbars(.7em, $gray-120);
  z-index: 2;
  padding: 0.3em;
  min-width: 220px;
  transition: all 0.5s;
}
.filterExtraHeight {
  max-height: calc(100vh - 265px);
}

.filterExtraHeightNoHeader {
  max-height: calc(100vh - 155px);
}

.filterNoHeader {
  max-height: calc(100vh - 245px);
}
