.breadcrumbNotActive {
  color: #999 !important;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
}
.breadcrumbActive {
  color: #333 !important;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
}
.breadcrumbArrow {
  margin-left: 10px;
  margin-right: 10px;
  color: #959595;
}

.breadcrumbMenu {
  margin: 0  0 1em 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
