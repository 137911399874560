.mainContainer {
  min-height: 100vh;
  height: 100%;
  position: relative;
  /* sticky footer using flex grow */
  display: flex;
  flex-direction: column;
}

.header {
  /* weird positioning is hacks for the box-shadow to be bottom only */
  box-shadow: 0px 6px 12px -8px rgba(0,0,0,0.06);
  border-bottom: 0.5px solid rgba(0,0,0,0.15);
  background-color: white;
  position: sticky;
  right: 0;
  left: 0;
  top:0;
  z-index: 999;
}

.content {
  flex-grow: 1;
}

.content > *:last-child:not(:only-child) {
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
}

.enter {
  opacity: 0.01;
}

.enterActive {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.appear {
  opacity: 0.01;
}

.appearActive {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.leave {
  opacity: 1;
}

.leaveActive {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

.caseyAvatar {
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: transparent;
}

.caseyImg {
  height: 90px;
  width: 90px;
}

.footer {
  /* bottom: 0; */
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  font-size: 13px;
  /* position: absolute; */
  background-color: #F6F7FA;
}

.footer > * {
  margin-right: 1rem;
}

.button {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  border-radius: 8px;
  background-color: #48a3db;
  cursor: pointer;
  outline: none;
}

.button:disabled {
  background-color: #e4e4e4;
  cursor: not-allowed;
}
