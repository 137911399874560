@import 'Components/global.scss';

.container {
  max-width: 700px;
  width: 100%;
}

.addEmpForm {
  width: 100%;
  display: grid;
  gap: 20px;
  row-gap: 10px;
  grid-template-columns: 1fr 1fr;

  fieldset > * { margin-bottom: 10px }

  .subgrid {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}

.carrierCheck {
  display: flex;
  justify-content: flex-start;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  padding-top: 4px;
  input[type=checkbox] {
    appearance: none;
    cursor: pointer;
    transition: all 0.25s;
    position: absolute;
    opacity: 0;
  }

  label {
    width: 6rem;
  }

  &:hover input[type=checkbox] ~ .radio {
    background-color: $gray-20;
  }

  input[type=checkbox]:checked ~ .radio:after {
      display: block;
  }
  input[type=checkbox]:checked ~ .radio {
    background-color: $periwinkle;
  }

  img {
    margin-right: 0.5em;
    z-index: 2;
  }
}

.radio {
  position: absolute;
  cursor: pointer;
  height: 21px;
  width: 20px;
  background-color: $gray;
  transition: background-color 100ms ease-in;
  border-radius: 3px;
  border: 1px solid $secondary-color;
  content: "√";
  box-shadow: inset 1px 1px 1px black;

  &::after {
    content: "√";
    font-family: Verdana, sans-serif;
    font-size: 1rem;
    position: absolute;
    display: none;
    top: -2px;
    left: 2px;
    color: white;
  }
}

.radioBackground {
  background-color: $gray-20;
}

.checkLabel {
  margin-left: 1.5rem;
  display: block;
  width: 168px;
}