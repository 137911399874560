@import 'Components/global.scss';

.indexPage {
  display: flex;
  padding-top: 0;
  padding-bottom: 60px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 100%;

  h1 {
    color: #327299;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  h2, h3 {
    margin: 0px;
    line-height: 100%;
  }
  h2 {
    font-weight: bold;
    color: #327299;
  }

  p {
    margin: 0
  }

  .employee, .employer, .agency,
  .linkout {
    position: relative;
    padding: 16px;
    min-width: 220px;
    background: #eee ;
    width: 100%;

    a {
      display: block;
      text-decoration: none;
      color: #666;

      h3 {
        display: block;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 22.4px */
        margin: 0;
        margin-bottom: 16px;
        color: #2D2D2D;
      }

      .button {
        transition: background-color 200ms linear;
        position: absolute;
        bottom: 10px;
        right: 10px;
        margin-left: 40px;
        color: #fff;
        display: flex;
        height: 32px;
        padding: 10px 10px;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background-color: #666;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
      }

      &:hover {
        span {
          background-color: #333;
        }
      }

      padding-bottom: 30px;
    }
  }

  .agency {
    background: #EFEEFA;
    width: 50%;

    a {

      h3 {
        color: #6258D1;
        font-weight: 600;
      }

      .button {
        background: #6258D1;
      }

      &:hover {
        span {
          background: #3b357d;
        }
      }
    }
  }

  .employee {
    background: #d4f0e3;
    width: 100%;

    a {

      h3 {
        color: #1d7e51;
        font-weight: 600;
      }

      .button {
        background: #1d7e51;
      }

      &:hover {
        span {
          background: #10482e;
        }
      }
    }
  }

  .employer {
    background: #daedf8;
    width: 100%;

    a {

      h3 {
        color: #4193c5;
        font-weight: 600;
      }

      .button {
        background: #4193c5;
      }

      &:hover {
        span {
          background: #2b6283;
        }
      }
    }
  }

  .mainCol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 70%;
  }

  .sideCol {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    article {
      padding: 20px;
      background: var(--gray-5, #FAFAFA);
      color: #444;

      h2 {
        color: #2D2D2D;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        margin-bottom: 16px;
      }
    }

    .shoplink {
      margin-top: 8px;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 100%;
      padding: 5px;
      border: #ccc solid 1px;
      background-color: #eee;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 50%;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }
}
