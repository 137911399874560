@import 'Components/global.scss';

.allAncillarySection {
  background: green(25);
  padding: 3rem;
  margin-bottom: 1rem;
}

.aa_cart {
  background-color: white;
  padding: 2.5rem;
  border-radius: 4px;

  .as_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  h2 {
    font-size: 1.5rem;
    font-family: $proxima;
    color: navy-blue();
    margin: 0;
  }

  h3 {
    font-size: 16px;
    font-family: $proxima;
    font-weight: 600;
    color: navy-blue();
    margin: 0;
  }

  p {
    font-family: $zilla;

    &.disclaimer {
      font-size: 14px;
      font-style: italic;
    }

    &.remaining {
      color: periwinkle();
      font-weight: 600;
      font-style: italic;
      margin-bottom: .5em;
    }
  }

  .semiBold {
    font-weight: 600;
  }

  .amount {

    font-size: 3rem;
    font-weight: 600;

    &.ee { color: $navy-blue }
    &.er { color: green() }

    span {
      font-size: .5em;
      font-weight: 700;
      line-height: 1.8em;

      &:first-child {
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}

.periwinkle {
  background-color: $periwinkle;
}

.darkGreen {
  color: green(140)
}

.border {
  border: 1px solid gray(30);
}