@import "Components/global.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,200;0,400;0,500;0,600;1,300;1,400&display=swap");

.pipelineContainer {
  display: flex;
  flex-direction: row;
}
.head {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: none;
  padding-bottom: 24px;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
}
.clientDisclaimer {
  font-style: italic;
  font-weight: normal;
  background: #f4f4f4;
  border: 1px solid #ebebeb;
  margin: 8px 0px;
  padding: 13px 40px 13px 15px;
  .discTxt {
    font-family: "Barlow Semi Condensed", sans-serif !important;
    font-size: 14px;
    line-height: 17px;
  }
}
// report generate
.downloadReport {
  height: 2.5rem;
  align-items: center;
  background: $blue-gradient;
  padding: 0 20px;
  border-radius: 4px;
  color: white;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 800;
  margin: 0 0 0 auto;

  i {
    margin-right: 0.5rem;
  }
}

.reporting_container {
  width: 470px;
  margin-top: 40px;
}
.reporting_inner_cont {
  background: #fcfcfc;
  border: 2px solid #f0f8fc;
}
.innerReport {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 8px;
  font-family: "Barlow Semi Condensed", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.innerSideLabel {
  font-family: "Barlow Semi Condensed", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.repName {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: #fcfcfc;
  border-bottom: 2px solid #f0f8fc;
  padding: 15px 10px 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

//In-progress stages container
.pipelineContainer > div:first-of-type {
  margin-right: 16px;
  width: calc(62% - 0.5rem);
}

//Benefits transitions stages
.pipelineContainer > div:nth-child(2) {
  width: calc(38% - 0.5rem);
}

//Stages container
.pipelineContainer > div > div:first-child {
  display: flex;
}

.completion > a {
  height: 100%;
  display: inline-block;
  text-decoration: none;
}

.activeGroups,
.inProgress {
  margin-top: 0.5rem;
  height: 27px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.1rem;
}

.inProgress {
  width: 71.5%;
  background-color: $navy-blue;

}

.activeGroups {
  background-color: $gray;
  width: 28.5%;
}

.stages {
  display: flex;
  flex-direction: row;

  a:hover {
    path:first-of-type {
      opacity: 1;
      fill: #9696bc;
    }

    text {
      fill: $off-white;
    }
  }

  // Create gap between completed and archived
  a:nth-last-of-type(2) {
    margin-right: 15px;
  }
}

.stages + .completion {
  width: calc((100% - 34px) / 9 *8);

  .activeGroups {
    background-color: $mint;
  }
}

.activeLabel {
  color: $secondary-color;
}

.inProgressLabel {
  color: white;
}

.today {
  position: relative;
  top: -3px;
  color: $secondary-color;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
}

.title {
  display: inline-block;
  margin-right: 30px;
  color: $blue;
  font-size: 40px;
  font-weight: 500;
  line-height: 76px;
  margin-bottom: 0;
}

.archivedContainer {
  display: inline-block;
  margin-left: 1vw;
}

.caution {
  color: caution();
}
.concern {
  color: concern(140);
}
.safe {
  color: green();
}

.pipelineTableText {
  font-family: Barlow Semi Condensed, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.pipelineTableTextWordWrap {
  font-family: Barlow Semi Condensed, sans-serif;
  font-weight: 400;
  font-size: 16px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.stagesContainer {
  display: flex;
  flex-direction: column;
}

.backToClientsBtn {
  font-family: $proxima;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: #686868;
  margin: 0px 6px;
}