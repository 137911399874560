@import 'Components/global.scss';

.producer {
  display: grid;
  grid-template-columns: $profile-section-grid;
  justify-items: center;
  gap: 40px;
}

.container > :not(:first-child):not(:nth-child(2)) {
  margin-bottom: 30px;
  border-top: 2px solid rgba(72, 163, 219, 0.08);
}

.separator {
  border: none;
  border-bottom: 2px solid #F0F8FC;
  margin-top: 40px;
}

.assignBrokerForm {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  align-items: center;

  fieldset {
    display: contents;
  }

  select {
    margin: 15px 0 25px;
    width: 100%;
    display: block;
    height: 48px;
    border: none;
    font-family: $barlow;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.02rem;

    &.placeholder {
      color: $placeholder-gray
    }
  }

  input[type=submit] {

    transition: all 300ms ease-out;
    background-image: $blue-gradient;

    &:disabled {
      color: $dodo;
      background: $gray;
      border: 4px solid $dodo;
    }
  }

  label {
    text-transform: uppercase;
    font-size: .75rem;
    letter-spacing: 0.1rem;
    text-align: center;
    color: $secondary-color;
  }

}