@import 'Components/global.scss';
.censusErrors {
  background-color: #FFD9CD;
  border: 1px solid #F13A00;
  border-radius: 5px;
  padding: 1rem;
  color: #671900;
  max-height: 300px;
  overflow-y: auto;
  .errorsTitle {
    font-size: 1.5rem;
    background-color: colorShade($gray-base, 20);
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid $orange;
    color: $gray-140;
    margin-bottom: 0.5rem;
  }
  h3, h4 {
    color: #671900;
    font-weight: bold;
  }
  .rowInfo {
    margin-bottom: 0;
    span:nth-of-type(1) {
      background-color: colorShade($gray-base, 30);
      border: 1px solid $red;
      border-radius: 3px;
      color: $red;
    }
    span:nth-of-type(2) {
      background-color: colorShade($light-periwinkle, 20);
      border: 1px solid $blue;
      color: $blue;
      border-radius: 0 3px 3px 0;
      border-left: unset;
      padding-left: 1rem;
    }
    span {
      padding: 0.15rem;
      font-weight: 400;
      font-size: 0.85rem;
    }
    span.hasName {
      border-right: unset;
      border-radius:3px 0 0 3px;
      padding-right: 1rem;
    }
  }
  ul {
    margin-top: 0.25rem;
  }

}

/* width */
.censusErrors::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.censusErrors::-webkit-scrollbar-track {
  background-color: #FF6737;
  // background-color: colorShade($gray-base, 40);
}

/* Handle */
.censusErrors::-webkit-scrollbar-thumb {
  background: #671900;
  // background-color: colorShade($gray-base, 60);
  border-radius: 0 3px 3px 0;
}