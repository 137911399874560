@import 'Components/global.scss';

.contactContainer {
  border-bottom: 1px solid $dodo;
  padding: 1rem 0;

  button {
    flex: 0 0 auto;
    background: $submit-gradient;
    padding: .5rem 1rem;
    border-radius: .25rem;
    font-size: .75rem;
    color: white;
    font-weight: 700;
    letter-spacing: 0.08rem;
    text-transform: uppercase;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }

  }
}

.contact {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: $secondary-color;

  div {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
    border-radius: 50%;
    background-color: white;
    line-height: 3.5rem;
    font-weight: 600;
    color: inherit;
    text-align: center;
  }

  button {
    margin-left: 1rem;

    &:first-of-type{
      background: $blue-gradient;
    }
  }

  span {
    flex: 1;
  }

}

.notes {
  margin-top: 1rem;

  button {
    margin-top: 1rem;
  }

}