.pb-container {
  position: relative;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 0 0 calc(50% - 5px);
  height: 62px;
  border-radius: 8px;
  background-color: #48a3db;
}
.pb-container .pb-button {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: #48a3db;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pb-container .pb-button span {
  display: inherit;
  transition: opacity 0.3s 0.1s;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
}
.pb-container .pb-button svg {
  margin: auto;
  height: 40px;
  width: 100%;
  position: absolute;
  pointer-events: none;
}
.pb-container .pb-button svg path {
  opacity: 0;
  fill: none;
}
.pb-container .pb-button svg.pb-progress-circle {
  animation: spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710);
}
.pb-container .pb-button svg.pb-progress-circle path {
  stroke: currentColor;
  stroke-width: 5;
}
.pb-container .pb-button svg.pb-checkmark path,
.pb-container .pb-button svg.pb-cross path {
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 4;
}
.pb-container.disabled {
  opacity: 0.5;
}
.pb-container.disabled .pb-button {
  cursor: not-allowed;
}
.pb-container.loading .pb-button {
  width: inherit;
  cursor: wait;
  background-color: transparent;
  padding: 0;
}
.pb-container.loading .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.loading .pb-button .pb-progress-circle > path {
  transition: opacity 0.15s 0.3s;
  opacity: 1;
}
/* .pb-container.success .pb-button {
  border-color: #08B577;
  background-color: #08B577;
} */
.pb-container.success .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.success .pb-button .pb-checkmark > path {
  opacity: 1;
}
/* .pb-container.error .pb-button {
  border-color: #ED5565;
  background-color: #ED5565;
} */
.pb-container.error .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.error .pb-button .pb-cross > path {
  opacity: 1;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: center center;
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
    transform-origin: center center;
  }
}
