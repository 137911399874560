@import 'Components/global.scss';
$box-margin: .625rem;
$corner-radius: .25rem;

.rateContainer {
  margin: $box-margin $box-margin $box-margin 0;
  border-radius: $corner-radius;
  flex: 0 0 320px;
  background-color: white;
}

.title {
  background-color: $periwinkle;
  border-radius: $corner-radius $corner-radius 0 0;
  text-align: center;
  font-weight: 700;
  color: white;
  font-size: 0.875rem;
  height: 2rem;
  line-height: 2rem;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(100% - 2rem);
}

.priceRange {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dollar {
  display: inline-block;
  vertical-align: top;
  line-height: 1.25rem;
  margin-right: 0.075rem;
  padding-bottom: 10px;
  font-weight: 600;
  color: $navy-blue;
  font-size: 1.5rem;
}

.price {
  font-size: 3rem;
  font-weight: 600;
  display: inline-block;
  line-height: 1.25rem;
  margin-right: 0.075rem;
  color: $navy-blue;
}

.rateText {
  color: colorShade($navy-blue, 70);
  margin: 1rem 0 1rem 0;
  font-weight: 600;
  font-size: 1rem;
  background-color: colorShade($periwinkle, 10);
  padding: 0.2rem;
  border-radius: 5px;
  border: 1px solid $periwinkle;
}

.rateDescription {
  color: $navy-blue;
  margin: 1rem 3rem;
  font-weight: 400;
  font-size: 0.7rem;
  padding: 0.3rem;
  border-radius: 5px;
  border: 0;
  font-style: italic;
}

.descDollar {
  font-size: 0.75rem;
  display: inline-block;
  vertical-align: top;
}
