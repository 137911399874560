@import "Components/global.scss";

.mainContainer {
  max-width: 800px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0;
  flex: 1;

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .additionsContainer {
    width: 81%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    button {
      border-radius: 0.5rem;
      height: 3rem;
      font-size: 1rem;
      font-weight: 300;
      color: white;
      background-color: blue;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      &:disabled {
        background-color: blue;
        cursor: not-allowed;
      }
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      cursor: pointer;
      svg {
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 12px;
      }
      text {
        font-family: $proxima;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #3564b9;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
  }

  .addProducersQueryContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    height: 96px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 25px;
    margin-bottom: 10px;
    width: inherit;
    .addProducersQuery {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      width: 755px;
      margin-bottom: 50px;
      .addProducersQueryButtons {
        order: 1;
        .yes {
          width: 98px;
          height: 56px;
          border: 1px solid #959595;
          border-radius: 8px;
        }
        .no {
          width: 98px;
          height: 56px;
          border: 1px solid #959595;
          border-radius: 8px;
        }
      }
      .addProducersQueryTextContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 48px;
        .line {
          width: 47px;
          height: 0px;
          transform: rotate(90deg);
        }
        p {
          width: 417px;
          font-family: $proxima;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 120%;
          color: #16346f;
        }
      }
    }
  }

  .addProducerButton {
    width: 366px;
    height: 48px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.16));
    font-family: $proxima;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.035em;
    color: #ffffff;
    background: #3564b9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    span {
      margin-right: 5px;
    }
  }

  h1 {
    margin: 40px auto 16px;
    text-align: center;
    font-family: $proxima;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    color: #372da3;
  }

  h2 {
    margin-top: 0 !important;
    margin-bottom: 40px;
    font-family: $proxima;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #16346f;
  }
}

.noticeBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 20px;

  .info {
    position: relative;
    width: 18px;
    height: 18px;
    right: 220px;
    top: 37px;
    font-family: $zilla;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    background: navy-blue(70);
    color: white;
    border-radius: 50%;
    line-height: 18px;
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    transition: all 100ms ease-out;

    &:hover, &:focus { transform: scale(.97) }
    &:active {transform: scale(.95)}
  }

  .questionMark {
    width: 14px;
    margin: 0;
    font-family: "Zilla Slab";
    font-size: 72px;
    color: #d0cdf1;
  }

  .appointmentQuestion {
    font-family: $proxima;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;

    color: #16346f;
  }

  span {
    height: 43px;
    width: 4px;
    margin-top: 2%;
    margin-right: 2%;

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
