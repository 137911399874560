@import "Components/global.scss";

.mainContainer {
  max-width: 800px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0;
  flex: 1;

  h1 {
    margin-top: $shop-top-spacing - $container-padding-top;
    text-align: center;
  }
}
.benifitEnrolment {
  max-width: 1150px;
  margin: 0 auto;
  padding-left: 40px;
  p {
    font-size: 16px;
    color: #16346f;
    font-weight: 700;
    text-transform: uppercase;
    margin: 15px 0 12px 0;
  }
}
.erFormSec {
  background: white;
  padding: 30px 20px;
}
.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
  margin-top: 20px;
  // background: #FFFFFF;
}

.associations > * {
  width: 100%;
}

.associations.halfWidth > * > * {
  display: inline-block;
  width: calc(50% - 5px);
}

.associations > * > *:not(h3) {
  margin-top: 35px;
}

.associations.halfWidth > * > *:nth-child(odd) {
  margin-right: 10px;
}

.termsContainer {
  margin: 24px auto 1.5rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

p.caveats {
  font-size: 80%;
  margin-top: 0px;
  margin-bottom: 0.5em;
}

.individualOptOut {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 140%;

  /* identical to box height, or 14px */

  /* Caution / Base */
  color: #ff8159;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  *:first-child {
    flex: 1;
  }
}

.info {
  font-family: $zilla;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  background: navy-blue(70);
  color: white;
  border-radius: 50%;
  line-height: 18px;
  width: 18px;
  height: 18px;
  flex: 0 0 18px;
  margin-left: 1rem;
  transition: all 100ms ease-out;

  &:hover,
  &:focus {
    transform: scale(0.97);
  }
  &:active {
    transform: scale(0.95);
  }
}

.einModal {
  max-width: 600px;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: blue(50), $alpha: 0.5);
}
