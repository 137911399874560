.form {
  max-width: 100%;
  width: 500px;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  fieldset div {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
  }

  button[type=button] {
    font-size: .8rem;
    width: fit-content;
    justify-self: flex-end;
  }
}