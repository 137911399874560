@import 'Components/global.scss';

.container {
  width: 345px;
  background-image: linear-gradient(180deg, rgba(234, 244, 255, 0.2) 0%, rgba(216, 235, 253, 0.2) 100%);
  padding: 16px;
}

.content {
  margin-top: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  gap: 16px;
}

.status {
  font-weight: 700;
}

.links {
  height: 36px;
  line-height: 36px;
  background: $blue-gradient;
  display: block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  transition: transform 200ms ease-out;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 17px;
  &:link, &:visited, &:hover, &:active, &.payrollDeductions { color: white }
  &:hover { transform: scale(1.01) }
  &:active { transform: scale(.99) }
}