.mainContainer {
  padding: 30px 0;
  width: 800px;
  margin: 0 auto;
  text-align: center;
}
.benifitEnrolment{
  max-width: 1150px;
  margin: 0 auto;
  padding-left: 40px;
}
.benifitEnrolment > p{
  font-size: 16px;
  color: #16346F;
  font-weight: 700;
  text-transform: uppercase;
  margin: 15px 0 0 0;
}
.mainContainer > h2{
  font-weight: 600;
}
.workStatCont{
  background: white;
  padding: 30px 20px 10px 20px;
}
.textInput {
  background-color: hsla(210, 16%, 95%, 1);
  border-radius: 7px;
  box-shadow: none;
  border: none;
  font-family: inherit;
  font-size: 15px;
  color: hsla(0, 0%, 50%, 1);
  padding: 17px 23px;
  width: 100%;
  height: 55px;
  outline: none;
}

.textInput::placeholder {
  color: hsla(0, 0%, 69%, 1);
}

.noLabel {
  margin-top: 14px;
}

.labelAside {
  color: #89898A;
  font-size: 14px;
}

.inputLabel {
  display: block;
  padding-left: 23px;
  margin-bottom: 9px;
  margin-top: 13px;
  color: #1F2E4D;
  font-size: 15px;
}
.left {
  display: inline-block;
  width: 49%;
  vertical-align: top;
  margin-bottom: 20px;
}

.right {
  display: inline-block;
  margin-left: 2%;
  width: 49%;
  vertical-align: top;
  margin-bottom: 20px;
}

.formContainer {
  margin-top: 23px;
}

.textInput:focus {
  border: 1px solid #2684ff;
}
