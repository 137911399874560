@import 'Components/global.scss';


$docusign-button-height: 5rem;

.docusignContainer {
  width: 35rem;
  margin: 2.5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 1.5rem;
    color: $periwinkle;
    font-size: 1.25rem;
    text-align: center;
    margin-top: 0;
    font-weight: 700;
  }

  p {
    font-size: 1rem;
    color: $secondary-color;
    margin: 0 0 1.75rem;
    text-align: center;
  }

  > div {
    height: $docusign-button-height;
    background-color: #f4f4f4;
    width: 25rem;
    border-radius: .75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: background-color 200ms ease-out;

    > *:first-child:not(:only-child) {
      margin-right: .5rem;
    }

    i {
      color: white;
      font-size: 3.5rem;
    }

  }

}

div.loading > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.normal {
  cursor: pointer;
  background-color: $mint;
  a {
    text-decoration: none;
    text-align: center;
    line-height: $docusign-button-height;
    width: 100%;
    height: 100%;
  }
}

div.declined {
  background-color: $orange;
  color: white;
}

div.complete {
  background-color: $green;
  color: white;
}
