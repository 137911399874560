.backToTop {
  position: fixed;
  display: none;
  bottom: 90px;
  right:1%;
  width: 56px;
  height: 65px;
  line-height: 16.8px;
  background: #3564B9;
box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
border-radius: 4px 0px 0px 4px;
  font-size: 12px;
  color: #fff;
  padding: 20px 7px 8px 10px;
  cursor: pointer;
}
.backToTop:before{
      content: ' \005E';
    position: absolute;
    top: 6px;
    left: 23px;
    font-size: 20px;
    font-weight: 600;

}
