@import 'Components/global.scss';
.ee_aflac_accident_plan_module_Cont{
  background-color: #f4f4f4;
}

.planBodyContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  border-radius: 0 0 0.25rem 0.25rem;
  overflow: hidden;
  flex-wrap: nowrap;
}

.section {
  margin: 0rem 0.625rem 0 0;
}

.custom_specs {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-right: 0.625rem;
  padding-bottom: 0.625rem;
}

.custom_specs table {
  width: 100%;
  height: 100%;
  font-size: .875rem;
  text-align: left;
  border: none;
  padding: 0;
  color: #707070;
  border-collapse: collapse;
  background: white;
}

.custom_specs table thead {
  background: #29B473;
  border-radius: 4px 4px 0px 0px;
}

.custom_specs table th {
  color: white;
  height: 2rem;
  vertical-align: middle;
}

.custom_specs table th, .custom_specs table td {
  margin: 0;
  font-size: .875rem;
}

.custom_specs table td {
  color: #203C6F;
  vertical-align: text-top;
  padding: 12.5px 0;
}

.custom_specs table :is(td, th):first-child {
  font-weight: 700;
  width: 50%;
  padding-left: 2rem;
  padding-right: 1rem;
}

.custom_specs table :is(td, th):nth-child(2) {
  width: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom_specs table :is(td, th):last-child {
  width: 50%;
  padding-left: 1rem;
  padding-right: 2rem;
}
.custom_specs table :is(td):last-child{
  border-left: 1px solid #EAEAEA;
}
.custom_specs table tbody tr:nth-child(even) {
  background-color:rgba(148, 217, 185, 0.5);
}
.std_benefit_amount{
  width: 300px !important;
    margin-right: 14px;
    margin-top: 5px;
}
.criticalIllnessTitile{
  margin-bottom: 20px;
}
.criticalPlanTitle {
  width: 100%;
  height: 3.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $gray;
  padding-right: 1.25rem;
  min-width: 0;
  position: relative;
  overflow: hidden;
  > span:first-child { margin-left: 1.25rem; }
}
.title {
  flex: 1;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 1.5rem;
  min-width: 0;
  margin-top: .25rem; // To Visually center
  display: flex;
  flex-direction: column;
  justify-content: center;

  &, & > div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.vertFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.horizFlex {
  display: flex;
  flex-direction:row;
  justify-content: center;
}

.waitingPeriod {
  padding: 0 1rem;
}

.benefitBoxTitle {
  font-family: $proxima;
  font-weight: 700;
  font-size: 14px;
}

.testDiv {
  width: 100%;
  background-color: red;
}

.vertContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  &:last-child {
    margin-right: 1em;
  }
  flex-grow: 1;
}

.noLeftMargin {
  margin-left: 0 !important;
}
