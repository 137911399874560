@import 'Components/global.scss';

.moduleBlock {
  width: px(712);
  margin: px(16) 0 px(32);
  background: #f4f4f4;
  padding: px(7);
  border-radius: 0.25rem;
}

.disclaimer {
  font-style: italic;
  color: $navy-blue;
  font-weight: 400;
  font-size: px(6);
}

.noop {
  color: #fff;
  font-weight: bold;
  font-size: px(10);
  background: #CE3200;
  padding: px(7);
  text-align: center;
}

.pagination {
  text-align: right;
  color: $navy-blue;
  flex-grow: 1;
  font-size: 7px;
}

.tableTitle {
  display: flex;
  align-items: center;
  margin-bottom: px(16);
  span {
    margin:0 1em 0 0;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: auto;
      height: 1.5em;
    }
  }
  div {
    margin: 0 1em 0 0;
  }
  div:nth-of-type(1) {
    font-weight: 600;
    font-size: px(9);
    color: $navy-blue;
  }
  div:nth-of-type(2) {
    font-size: px(9);
    color: $navy-blue;
  }
}

.height {
  overflow-y: auto;
}
.wide {
  width: 712px;
}
.full {
  width: 100%;
}
.narrow {
  width: 712px;
}
.table {
  font-size: px(7);
  border-spacing: 0;
  margin: 0 0 1em 0;
  thead {
    tr {
      th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #305AA6;
        padding: 1em;
        font-weight: 700;
        font-size: px(7);
        letter-spacing: 0.08em;
        color: white;
        text-transform: uppercase;
        text-align: left;
        &.employerShare {
          background-color: #143066;
        }
        &.employeeShare {
          background-color: #176440;
        }
        span.parenthetical {
          font-weight: 300;
          text-transform: none;
        }
      }
    }
  }
  tbody {
    tr {
      background-color: #FFFFFF;
      &:nth-child(even) {
        background-color: #F4F4F4;

      }
      td {
        vertical-align: top;
        padding: px(7) px(6);
        color: #16346F;
        font-weight: 700;

        &.planCost {
          color: #197845;
        }
        &.lightFont {
          font-weight: 400;
        }
        &.uppercase {
          text-transform: uppercase;
        }
        &.employerShare {
          background-color: rgba(20, 48, 102, 0.9);
          color: white;
        }
        &.employeeShare {
          background-color: rgba(23, 100, 64, 0.9);
          color: white;
        }
        &.employerShareDark {
          background-color: rgba(20, 48, 102, 0.8);
          color: white;
        }
        &.employeeShareDark {
          background-color: rgba(23, 100, 64, 0.8);
          color: white;
        }
        .dependentTitle {
          margin: 1.5em 0 0 1em;
        }
        .dependentDescription {
          margin: 0 0 0 1em;
          font-style: italic;
          font-weight: 400;
          font-size: 7px;
          line-height: 140%;
        }
        .dependentDash {
          margin: 2em 0 0 0;
          font-weight: 600;
          font-size: 8px;
          line-height: 140%;
        }
        .dependantPremium {
          margin-top: 2em;
        }
      }
    }
  }
  tfoot {
    &.sticky tr td{
      position: sticky;
    }
    tr {
      td {
        background-color: #305AA6;
        color: white;
        font-weight: 700;
        padding: 1em;
        bottom: 0;
        z-index: 1;
        &.employerTotal {
          background-color: #143066;
        }
        &.employeeTotal {
          background-color: #176440;
        }
      }
    }
  }
}
