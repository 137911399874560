
@import 'Components/global.scss';

.container {
  width: 700px;
  max-width: 100%;
  height: 350px;
  background: linear-gradient(89.75deg, #EDF2FB 0.21%, rgba(237, 242, 251, 0) 99.79%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  img {
    justify-self: center;
    margin-right: 1rem;
  }
}

.prosperLogo {
  height: 2rem;
  width: auto;
  display: inline;
  margin-left: .75rem;
  vertical-align: middle;

  path { fill: navy-blue(80) }
}

.prosperIncluded {
  height: 100%;
  position: relative;
  background-color: white;
  padding: 1.25rem;
  width: fit-content;
  height: fit-content;

  h2 {
    font-family: $proxima;
    font-size: .875rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: navy-blue();
    text-align: left;
  }

  p {
    color: gray();
    width: 260px;
  }
}

.controls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: .25rem;
  padding: 0 .5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.stepNav{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.stepButton {
  height: .75rem;
  width: .75rem;
  border-radius: 100%;
  background-color: navy-blue(20);
  margin-right: 1rem;
  transition: transform 100ms ease-in;

  &:last-of-type { margin: 0 }
  &:hover { transform: scale(1.1, 1.1) }
}
.stepButtonActive { background-color: navy-blue() }

.arrow {
  background: none;
  z-index: 50;
  transition: transform 200ms ease-in-out ;
  text-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.2);
  color: navy-blue();

  &:hover {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }

  span { font-size: 40px }
}
