  @import 'Components/global.scss';

  .ee_aflac_accident_plan_module_Cont{
    width: 953px;
    margin: 0 auto;
    display: flex;
    margin-top: 2rem;
  }
  .individual_tier_container{
    flex-basis: 300px;
    padding: 0 10px 0 0px;
  }
  .heading{
  background: #29B473;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 0px;
  margin: 0 auto;
  display: flex;
  }

  .individual_tier{
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
  color: white;
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: .13rem;
  font-weight: 700;
  white-space: nowrap;
  }
  .individual_tier_price{
  padding: 31px 16px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #EAEAEA;
  border-radius: 0px 0px 4px 4px;
  text-align: center;
  background: white;
  height: 85%;
  }
  .eeContprice{
  margin-top: 21px;
  color: #29B473;
  padding: 0px 30px 0;
    span:first-child {
      display: inline-block;
      vertical-align: top;
      font-size: 1.5em;
      font-weight: 600;
      line-height: 1.8em;
      margin: 0;
      padding-top: 2px;
    }
  }

  .mon{
  font-size: 24px;
  font-weight: 700;
  }
  .amount{
  font-weight: 600;
  font-size: 48px;
  }
  .amountLong {
    font-weight: 500;
    font-size: 16px;
    color: $navy-blue;
  }
  .after_employer_contribution{
  color: #29B473;
  font-weight: 700;
  font-size: 12px;
  padding: 0px 16px;
  padding-top: 5px;
  }
  .your_employer_contri{
  color: #707070;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 30px 10px;
  line-height: 17px;
  }
  .dolarMonth{
  font-weight: 700;
  }
  .accident_deathBenefit{
  flex-basis: 300px;
  text-align: center;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 250px;
  margin-right: 5px;
  padding: 0 1em;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  }
  .accidental_death_benefit_Heading{
  padding: 0px 16px;
  }
  .accidental_death_benefit_Heading>p{
  margin-bottom: 8px;
  }
  .accidental_death_price {
    .amount{
    font-weight: 600;
    font-size: 24px;
    color: #16346F;
    line-height: 20px;
    }
    .dolar {
      font-size: 14px;
      font-weight: 600;
      color: #16346f;
      vertical-align: top;
      line-height: 15px;
    }

  }
  .hospital_benifit_amount{
  padding: 6px 20px 4px;
  }

  .initial_hospitalization_benefit{
  margin: 0 5px 10px 5px;
  text-align: center;
  height: 120px;
  min-width: 320px;
  background: white;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  }

  .noLeftMargin {
    margin: 0 5px 10px 0px;;
  }

  .hospital_confinement{
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #16346f;
  }
  .confinement__benefit{
  background: white;
  }

  .accident_hospital_confinement_benefit{
  padding: 32px 16px 0;
  }
  .hospital_benifit_confinement{
  padding-bottom:34px;
  }
  .benefit_amount_label{
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #16346F;
    margin-top: 4px;
}
.amountContainer{
  background-color: white;
  color: blue;
}
.amount_container{
  width: 200px;
  margin: 0 5px;
}

.benefit_amount{
  padding: 10px 16px;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  .benefit_amount{
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
  }
}
.base_on_your_income{
  font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #16346F;
}
.std_benifit_amount{
  padding: 22px 1px 6px;
}

.er {
  font-size: .875rem;
  font-family: $zilla;
  font-weight: 300;
  text-align: center;
  margin-top: 1.5rem;

  em {
    font-family: inherit;
    font-weight: 600;
  }

}

.portability_text {
  font-family: $proxima;
  color: $navy-blue;
  font-weight: 700;
  font-size: 14px;
}

.benefitBoxTitle {
  font-family: $proxima;
  font-weight: 700;
  font-size: 14px;
}

.ERCancerBoxTitle {
  background-color: $periwinkle;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 2em;
  font-size: 0.875rem;
  border-radius: 0.25rem 0.25rem 0 0;
}

.centerGrid {
  height: calc(100% - 2rem);
  display: grid;
  place-items: center;
}