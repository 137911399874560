.defaultSizing {
  height: 52px;
  width: 200px;
  background-color: #48a3db;
  color: #ffffff;
  font-size: 14px;
}

.defaultSizing:hover {
  font-size: 15px;
}

.cpb-container {
  display: flex;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  justify-content: center;
  margin: 0 auto;
}
.cpb-container .cpb-button {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: 600;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s;
  position: relative;
}
.cpb-container .cpb-button span {
  display: inherit;
  transition: opacity 0.3s 0.1s;
  color: inherit;
  font-size: inherit;
  font-weight: 600;
  transition: font-size 0.15s ease-in !important;
  -webkit-transition: font-size 0.15s ease-in !important;
}

.cpb-container .cpb-button:hover span {
  font-size: inherit;
}

.cpb-container .cpb-button svg {
  margin: auto;
  height: 40px;
  width: 40px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.cpb-container .cpb-button svg path {
  opacity: 0;
  fill: none;
}
.cpb-container .cpb-button svg.cpb-progress-circle {
  animation: spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710);
  /* Temp progress circle fix */
  top: 10px;
  left: 10px;
}
.cpb-container .cpb-button svg.cpb-progress-circle path {
  stroke: currentColor;
  stroke-width: 5;
}
.cpb-container .cpb-button svg.cpb-checkmark path,
.cpb-container .cpb-button svg.cpb-cross path {
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 4;
}
.cpb-container.disabled {
  opacity: 0.5;
}
.cpb-container.disabled .cpb-button {
  cursor: not-allowed;
  background-color: #CBCBCB;
}
.cpb-container.loading .cpb-button {
  width: 54px;
  cursor: wait;
  background-color: transparent;
  padding: 0;
}
.cpb-container.loading .cpb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.cpb-container.loading .cpb-button .cpb-progress-circle > path {
  transition: opacity 0.15s 0.3s;
  opacity: 1;
}
.cpb-container.success .cpb-button {
  border-color: inherit;
  background-color: inherit;
}
.cpb-container.success .cpb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.cpb-container.success .cpb-button .cpb-checkmark > path {
  opacity: 1;
}
.cpb-container.error .cpb-button {
  border-color: #ED5565;
  background-color: #ED5565;
}
.cpb-container.error .cpb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.cpb-container.error .cpb-button .cpb-cross > path {
  opacity: 1;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: center center;
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
    transform-origin: center center;
  }
}
