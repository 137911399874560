.base {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(112,112,112,0.4);
  z-index: 999;
}

.afterOpen {
  opacity: 1;
}

.beforeClose {
  opacity: 0;
}
