@import 'Components/global.scss';

.container {
  max-width: 100%;
  width: 500px;
  background-image: linear-gradient(30deg, #29b5aa 20%, #29b473 100%);

  * { color: white }

  h2 {
    font-size: 1rem;
    font-weight: 600;
    color: white;
    text-align: left;
    font-family: $proxima;
  }

  ul {
    font-size: 1rem;
    color: white;
    padding-left: 1rem;
    margin-bottom: 0;

    li { margin-bottom: .75rem }

    span {
      font-size: .75rem;
      font-style: italic;
    }
  }

  a:hover{ color: $periwinkle }
}

.header {
  border-bottom: 1px solid green(95);
  background: none;
}

.closeButton {
  path { stroke: white }
  &:hover path { stroke: navy-blue() }
}

.question {
  font-size: 1rem;
  margin: 1rem 0 .25rem;
  font-weight: 700;
}

.answer {
  font-size: 1rem;
  margin: 0;
  font-weight: 400;
}

.linkButton {
  text-decoration: underline;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}