@import 'Components/global.scss';

.mainContainer {
  display: grid;
  grid-template-columns: 500px 1fr;
  gap: 2rem;

  .illustration {
    width: 100%;
    height: auto;
  }

  h2 {
    font-size: 1.375rem;
    margin-top: 0;
    text-align: left;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    li, p {
      color: navy-blue();
    }

    li:not(:last-of-type) { margin-bottom: .75rem}
    a { font-family: inherit; }

    button {
      margin: 1rem auto 0 0;
    }
  }
}