.container {
  padding: 80px 0;
  width: 800px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  text-align: center;
}
.benifitEnrolment {
  max-width: 1150px;
  margin: 0 auto;
  padding-left: 40px;
}
p.employeBenift {
  font-size: 16px;
  color: #16346f;
  font-weight: 700;
  text-transform: uppercase;
  margin: 50px 0 30px 0;
}
.planContainer {
  background: white;
  padding: 30px 20px;
}

.radioContainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-top: 30px;
  margin-bottom: 12px;
  margin-right: 46px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: -20px;
}

.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radioContainer:hover input ~ .radio {
  background-color: #ccc;
}

.radio {
  position: absolute;
  top: -2px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f1f3f5;
  border-radius: 4px;
}

.radioContainer input:checked ~ .radio {
  background-color: #3564b9;
}

.radioContainer input:checked {
  padding: 2px;
}

.radio:after {
  content: "";
  position: absolute;
  display: none;
}

.radioContainer .radio:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.nextButton {
  border: none;
  outline: none;
  background-color: #48a3db;
  color: white;
  font-size: 15px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  margin-top: 70px;
  padding: 18px 120px;
}

.button {
  border: none;
  outline: none;
  background-color: #48a3db;
  color: white;
  font-size: 15px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 18px 40px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.renewalContainer {
  max-width: 500px;
}

.renewalInput {
  border: none;
  outline: none;
  width: 92px;
  font-size: 15px;
  color: #b2b2b2;
  padding: 0;
  background-color: #f1f3f5;
  text-align: center;
}

.renewalInputContainer {
  padding: 20px 30px;
  display: inline-block;
  background-color: #f1f3f5;
  text-align: center;
  border-radius: 7px;
}

.textInput {
  background-color: hsla(210, 16%, 95%, 1);
  border-radius: 7px;
  box-shadow: none;
  border: none;
  font-family: inherit;
  font-size: 15px;
  color: hsla(0, 0%, 50%, 1);
  padding: 17px 23px;
  height: 55px;
  outline: none;
  text-align: center;
}

.radioContainer input:active ~ .radio {
  background-color: #16346f;
}

.textInput:focus {
  border: 1px solid #2684ff;
}

.full {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  text-align: left;
  margin-bottom: 20px;
}

.left {
  display: inline-block;
  width: 49%;
  vertical-align: top;
  text-align: left;
}

.right {
  display: inline-block;
  margin-left: 2%;
  width: 49%;
  vertical-align: top;
  text-align: left;
  margin-bottom: 20px;
}

.inputLabel {
  display: block;
  padding-left: 23px;
  margin-bottom: 9px;
  margin-top: 0px;
  color: #1f2e4d;
  font-size: 15px;
  text-align: left;
  font-weight: 500;
}

.textInput {
  background-color: hsla(210, 16%, 95%, 1);
  border-radius: 7px;
  box-shadow: none;
  border: none;
  font-family: inherit;
  font-size: 15px;
  color: hsla(0, 0%, 50%, 1);
  padding: 17px 23px;
  width: 100%;
  height: 55px;
  outline: none;
  text-align: left;
}

.textInput::placeholder {
  color: hsla(0, 0%, 69%, 1);
}

.textInput:focus {
  border: 1px solid #2684ff;
}

.currentPlanMessage {
  font-size: 20px;
  color: #959595;
  margin-bottom: 35px;
  margin-top: 50px;
  display: block;
  font-weight: 600;
}
