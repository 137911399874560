@import 'Components/global.scss';
.mainContainer {
	/* Auto Layout */
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
}

.info {
	font-family: $zilla;
	font-size: 16px;
	font-weight: 600;
	font-style: italic;
	background: navy-blue(70);
	color: white;
	border-radius: 50%;
	line-height: 18px;
	width: 18px;
	height: 18px;
	flex: 0 0 18px;
	transition: all 100ms ease-out;

	&:hover, &:focus { transform: scale(.97) }
	&:active {transform: scale(.95)}
}

.addInvite {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	h1 {
		font-family: $proxima;
		font-style: normal;
		font-weight: 800;
		font-size: 48px;
		line-height: 120%;
		display: flex;
		align-items: center;
		text-align: center;
		color: #16346F;
		flex: none;
		order: 0;
		flex-grow: 0;
		margin: 16px 0px;
	}
	p {
		width: 896px;
		font-family: $proxima;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		color: #16346F;
		flex: none;
		order: 1;
		flex-grow: 0;
		margin: 16px 0px;
	}
}

.addProducersQueryContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px;
	width: 896px;
	height: 96px;
	background: #EFEEFA;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin-top: 25px;
	margin-bottom: 10px;
	.addProducersQuery {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0px;
		width: 856px;
		margin-bottom: 50px;
		.addProducersQueryButtons {
			order: 1;
			.yes {
				width: 98px;
				height: 56px;
				border: 1px solid #959595;
				border-radius: 8px;
			}
			.no {
				width: 98px;
				height: 56px;
				border: 1px solid #959595;
				border-radius: 8px;
			}
		}
		.addProducersQueryTextContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 436px;
			height: 48px;
			.line {
				width: 47px;
				height: 0px;
				border: 4px solid #6258D1;
        background: #6258D1;
				transform: rotate(90deg);
			}
			p {
				width: 417px;
				font-family: $proxima;
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 120%;
				color: #16346F;
			}
		}
	}
}

.producerCensusContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	.formContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-self: center;
		width: 100%;
		margin-top: 20px;
	}
	.addProducerButton {
		width: 366px;
		height: 48px;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.16));
		font-family: $proxima;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.035em;
		color: #FFFFFF;
		background: #3564B9;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
		border-radius: 6px;
		span {
			margin-right: 5px;
		}
	}
	.addorinviteContainer {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 0px;
		width: 100%;
		height: 26px;
		flex: none;
		order: 0;
		flex-grow: 0;
		margin-top: 25px;
		.inviteBtn {
			font-family: $proxima;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			letter-spacing: 0.035em;
			color: #686868;
		}
		.addProducersBtn {
			font-family: $proxima;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			text-align: center;
			letter-spacing: 0.035em;
			margin-right: 10%;
			color: #372DA3;
		}
	}
	.underline {
		border: 3px solid #372DA3;
    background: #372DA3;
		margin-bottom: 25px;
	}
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 30px 20px 20px;
	margin-bottom: 25px;
	position: relative;
	background: #F4F4F4;
	.additionsContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		button {
			border-radius: .5rem;
			height: 3rem;
			font-size: 1rem;
			font-weight: 300;
			color: white;
			background-color: blue;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
			&:disabled {
				background-color: blue;
				cursor: not-allowed;
			}
		}
	}
	.form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: .625rem;
		margin-bottom: 1.5rem;
		input {
			background-color: white;
      color: #16346F;
		}
    label {
      z-index: 0;
    }
		.npnGroup{
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0px;
			width: 422px;
			flex: none;
			order: 1;
			flex-grow: 0;
			div{
				width: inherit;
				margin-right: 10px;
			}
		}
	}
	.nameRow {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 1rem;
		.name {
			font-family: $proxima;
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 100%;
			letter-spacing: 0.06em;
			text-transform: uppercase;
			color: #16346F;
			flex: 1;
		}
	}
	.delete {
		font-family: $proxima;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 140%;
		color: #F13A00;
	}
	.addProducerButton {
		width: 366px;
		height: 48px;
		background: #3564B9;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
		border-radius: 6px;
	}
	.addAnItem {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0px;
		cursor: pointer;
		svg {
			flex: none;
			order: 0;
			flex-grow: 0;
			margin: 0px 12px;
		}
		text {
			font-family: $proxima;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			color: #3564B9;
			flex: none;
			order: 1;
			flex-grow: 0;
		}
	}
}

.stateLicenseContainer {
  input {
    color: #16346F;
  }
	.stateLicensesSubcontainer {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-bottom: 20px;
    .stateInput {
      width: 120px;
      height: 56px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-right: 15px;
      div {
        color: #16346F;
      }
    }
		.licenseInput {
      width: 282px;
      height: 56px;
      background: #FFFFFF;
      flex: none;
      order: 1;
      flex-grow: 0;
		}
    label {
      z-index: 0;
    }
	}
}

.CarrierAppointmentComponentContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	flex: none;
	order: 1;
	flex-grow: 0;
	.stateTitleForCarrierComp {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		flex: none;
		order: 0;
		align-self: stretch;
		flex-grow: 0;
		.stateText {
			font-family: $proxima;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 140%;
			letter-spacing: 0.035em;
			text-transform: uppercase;
			color: #16346F;
			flex: none;
			order: 0;
			flex-grow: 0;
		}
		.removeCarrAppButton {
			font-family: $proxima;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			text-align: right;
			color: #F13A00;
			flex: none;
			order: 1;
			flex-grow: 0;
		}
	}
	.addCarrierComponent {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 0px;
		flex: none;
		order: 1;
		flex-grow: 0;
		margin: 20px 0px;
		.carrierAgentMainComponent {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0px;
			flex: none;
			order: 1;
			flex-grow: 0;
			.carrierAgentHeaderComponent {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0px;
				flex: none;
				order: 0;
				flex-grow: 0;
				text {
					font-family: $proxima;
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 140%;
					color: #16346F;
					flex: none;
					order: 0;
					flex-grow: 0;
					margin: 0px 74px;
				}
				.AgentCodeComponent {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: flex-start;
					width: 113px;
					flex: none;
					order: 1;
					flex-grow: 0;
					svg {
						flex: none;
						order: 1;
						flex-grow: 0;
					}
					text {
						width: 86px;
						font-family: $proxima;
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 140%;
						color: #16346F;
						flex: none;
						order: 0;
						flex-grow: 0;
						margin: 0px 9px;
					}
				}
			}
			.carrierAgentEntryComp {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0px;
				height: 56px;
				flex: none;
				order: 1;
				flex-grow: 0;
        input{
          color: #16346F;
        }
				.agentCodeBox {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 17px 20px;
					width: 232px;
					height: 30px;
					background: #FFFFFF;
					border: 1px solid #AAAAAA;
					box-sizing: border-box;
					border-radius: 6px;
					flex: none;
					order: 1;
					flex-grow: 0;
					margin-left: 55px;
				}
				.carrierBoxComponent {
					flex: none;
					order: 0;
					flex-grow: 0;
					margin-left: 70px;
					width: 180px;
					.carrierBox {
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 4px 16px;
						width: fit-content;
						background: #E0DEF6;
						border-radius: 22px;
						text {
							font-family: $proxima;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 140%;
							color: #16346F;
							flex: none;
							order: 0;
							flex-grow: 0;
						}
					}
				}
			}
		}
		.dropdownCarriers {
			flex: none;
			order: 0;
			flex-grow: 0;
			width: 280.49px;
		}
	}
}

.inviteProducersContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	background: #FFFFFF;
	.sendInvitesAllContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 24px 20px;
		width: 896px;
		background: #EDF6FB;
		flex: none;
		order: 0;
		flex-grow: 0;
		.sendInvitesAllSubContainer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-right: 20%;
			button {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 17px 26px;
				width: 258px;
				height: 56px;
				color: white;
				background: #5C90D5;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
				border-radius: 6px;
				flex: none;
				order: 1;
				flex-grow: 0;
			}
			div {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 0px;
				width: 479px;
				height: 88px;
				flex: none;
				order: 0;
				flex-grow: 0;
				margin: 0px 119px;
			}
			.sendAllInviteParagraphDiv {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0px;
				flex: none;
				order: 0;
				flex-grow: 0;
				p {
					width: 460px;
					font-family: $proxima;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 100%;
					margin-left: -5%;
					color: #16346F;
					flex: none;
					order: 1;
					flex-grow: 0;
				}
				svg {
					width: 87px;
					height: 0px;
					border: 4px solid #C0D0ED;
					transform: rotate(90deg);
					flex: none;
					order: 0;
					flex-grow: 0;
				}
			}
		}
	}
	.sendInvitesSomeContainer {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		flex: none;
		order: 1;
		flex-grow: 0;
		margin: 40px 0px;
		.sendInvitetoSelectedText {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 17px 26px;
			width: 302px;
			height: 56px;
			background: #FFFFFF;
			border: 2px solid #5C90D5;
			box-sizing: border-box;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
			border-radius: 6px;
			flex: none;
			order: 0;
			flex-grow: 0;
			button {
				font-family: $proxima;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 140%;
				text-align: center;
				color: #5C90D5;
				flex: none;
				order: 0;
				flex-grow: 0;
				margin: 0px 10px;
			}
		}
		.producerListToBeInvited {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0px;
			flex: none;
			order: 1;
			flex-grow: 0;
			margin-top: 20px;
			.producerInviteeContainer {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 24px 40px;
				height: 70px;
				background: #FAFAFA;
				border-radius: 4px;
				flex: none;
				order: 1;
				flex-grow: 0;
				margin: 4px 0px;
				.producerInviteeInnerContainer {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					padding: 0px;
					width: 816px;
					flex: none;
					order: 0;
					flex-grow: 0;
					margin: 10px 0px;
					.nameSection {
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 0px;
						flex: none;
						order: 0;
						flex-grow: 0;
						label {
							display: flex;
							flex-direction: row;
							justify-content: flex-start;
							align-items: flex-start;
							margin: 1rem 0;
							div {
								margin-left: 10px;
							}
						}
					}
				}
			}
		}
	}
}