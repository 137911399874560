@import 'Components/global.scss';

.moduleBlock {
  margin: px(16) 0 px(32);
  background: #f4f4f4;
  padding: px(16);
  border-radius: 0.25rem;
}


.modalTitle {
  display: flex;
  align-items: center;
  margin: 1em 0;
  div {
    margin: 0 1em 0 0;
  }
  div:nth-of-type(2) {
    font-weight: 600;
    font-size: 16px;
    color: $navy-blue;
  }
  div:nth-of-type(3) {
    font-size: 16px;
    color: $navy-blue;
  }
}

.height {
  max-height: px(500);;
  overflow-y: auto;
}
.wide {
  width: 1167px;
}
.full {
  width: 100%;
}
.narrow {
  width: 940px;
}
.table {
  border-spacing: 0;
  width: 100%;
  thead {
    tr {
      th {
        &:first-of-type {
          border-radius: 5px 0 0 0;
        }
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #305AA6;
        padding: 1em;
        font-weight: 700;
        letter-spacing: 0.08em;
        color: white;
        text-transform: uppercase;
        text-align: left;
        &.employerShare {
          background-color: #143066;
        }
        &.employeeShare {
          background-color: #176440;
        }
        &:last-of-type {
          border-radius: 0 5px 0 0;
        }
        span.parenthetical {
          font-weight: 300;
          text-transform: none;
        }
      }
    }
  }
  tbody {
    tr {
      background-color: #FFFFFF;
      &:nth-child(even) {
        background-color: #F4F4F4;

      }
      td {
        vertical-align: top;
        &.planCost {
          color: #29B473;
        }
        padding: 1em;
        color: #16346F;
        font-weight: 700;
        &.lightFont {
          font-weight: 400;
        }
        &.uppercase {
          text-transform: uppercase;
        }
        &.employerShare {
          background-color: rgba(20, 48, 102, 0.9);
          color: white;
        }
        &.employeeShare {
          background-color: rgba(23, 100, 64, 0.9);
          color: white;
        }
        &.employerShareDark {
          background-color: rgba(20, 48, 102, 0.8);
          color: white;
        }
        &.employeeShareDark {
          background-color: rgba(23, 100, 64, 0.8);
          color: white;
        }
        .dependentTitle {
          margin: 1.5em 0 0 1em;
        }
        .dependentDescription {
          margin: 0 0 0 1em;
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
        }
        .dependentDash {
          margin: 2em 0 0 0;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
        }
        .dependantPremium {
          margin-top: 2em;
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        &:first-of-type {
          border-radius: 0 0 0 5px;
        }
        position: sticky;
        background-color: #29B473;
        color: white;
        font-weight: 700;
        padding: 1em;
        bottom: 0;
        z-index: 1;
        &.employerTotal {
          background-color: #143066;
        }
        &.employeeTotal {
          background-color: #176440;
        }
        &:last-of-type {
          border-radius: 0 0 5px 0;
        }
      }
    }
  }
}
