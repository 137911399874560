@font-face {
  font-family: Proxima Nova;
  src: url('./ProximaNovaRegularProposals.ttf') format('truetype');
}

@font-face {
  font-family: Proxima Nova;
  font-weight: bold;
  src: url('./ProximaNovaBoldProposals.ttf') format('truetype');
}

@font-face {
  font-family: Proxima Nova;
  font-style: italic;
  src: url('./ProximaNovaItalicProposals.ttf') format('truetype');
}
