@import '../plan-subcomponents/Plan.module.scss';

.er, .ee {
  display: grid;
  gap: $box-margin;
  flex: 1 1;
  margin: $box-margin $box-margin * 2 $box-margin 0
}

.ee {
  grid-template:
    "a b c";
    grid-template-columns: 1fr 1fr 1fr;
  > *:first-child { grid-area: a }
  > *:nth-child(2) { grid-area: b }
  > *:nth-child(3) { grid-area: c }
}

.frequency {
  font-size: .75rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .06em;
}

.flexClass {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}