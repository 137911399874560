.frame2615 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  right: 235px;
  top: 15px;

  .frame2614 {
    display: flex;
    flex-direction: column;

    position: relative;
    width: 236px;
    height: 44px;

    order: 1;

    .groupNameText {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 120%;

      display: flex;
      align-items: center;

      color: #333333;

      margin: 0px 0px;
    }
  }

  .frame2613 {
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    height: fit-content;

    margin-bottom: 10px;
  }
}