.form {

  input {
    background-color: white;
    margin-bottom: .625rem;
  }

  input[type=submit] {
    width: 100%;
    margin-top: 1rem;
  }
}