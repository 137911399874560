@import '../plan-subcomponents/Plan.module.scss';

.specs {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    flex: 1;

    // Minimum Benefits
    &:first-child, &:nth-child(2), &:nth-child(3) {
      text-align: center;
      flex: 0 0 calc(33.33% - #{$box-margin});

      span { color: $navy-blue; }
    }

    // Will, AD&D
    &:nth-child(4), &:nth-child(5) {
      flex: 0 0 calc(50% - #{$box-margin});
    }

    .largeFigure {
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: $navy-blue;
      margin: .5rem 0 0;
      font-weight: 600;
    }

    .label {
      padding: 1em;
      text-align: center;
      color: $gray-130;
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: 0.03em;
    }

    .ERLabel {
      padding: 0.5em 1em;
      text-align: center;
      background-color: $periwinkle;
      color: white;
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: 0.03em;
    }

    .value {
      text-align: center;
      margin: 0.5em 2em;
    }

    .ERValue {
      text-align: center;
      margin: 1.5em 2em;
    }

    span {
      font-size: .875rem;
      display: block;
      color: $navy-blue;
      line-height: .875rem;
      margin-top: .5rem;
      font-weight: 600;
    }
  }
}