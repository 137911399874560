@import 'Components/global.scss';
$box-margin: .625rem;
$corner-radius: .25rem;

.rateContainer {
  &:first-child {
    margin-bottom: $box-margin;
  }
  border-radius: $corner-radius;
  flex: 0 0 320px;
  background-color: white;
}

.title {
  background-color: $periwinkle;
  border-radius: $corner-radius $corner-radius 0 0;
  text-align: center;
  font-weight: 700;
  color: white;
  font-size: 0.875rem;
  height: 2rem;
  line-height: 2rem;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 2rem);
}

.priceRange {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dollar {
  display: inline-block;
  vertical-align: top;
  line-height: 1.25rem;
  margin-right: 0.075rem;
  padding-bottom: 10px;
  font-weight: 600;
  color: $navy-blue;
  font-size: 1.5rem;
}

.price {
  font-size: 3rem;
  font-weight: 600;
  display: inline-block;
  line-height: 1.25rem;
  margin-right: 0.075rem;
  color: $navy-blue;
}

.dash {
  color: $navy-blue;
  font-size: 3rem;
  font-weight: 400;
}
