@import 'Components/global.scss';

.header {
  margin-bottom: 30px;
  background-color: #16346f;
  color: white;
}
.eeHeading{
  background-color: #155B3A;
}
 .coverageHeading{
  background-color: #155b3a;
  h1{
    font-weight: 700;
  }
  p{
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}
.cartContainer{
  background: #155B3A;
}
.wrapper {
  @include default-content-sizing;
  padding-bottom: 50px;
  padding-top: 50px;

  > *:last-child { margin-bottom: 0 }

  h1 {
    font-size: 2.5rem;
    color: inherit;
    margin-bottom: 1em;
  }

  p {
    font-size: 22px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 2em;
    font-weight: 600;
    letter-spacing: .02em;
  }
}

.noPadding {
  padding-bottom: 0px;
}

.noPMargin {
  p {
    margin: 0 0 2em 0;
  }
}