@import 'Components/global.scss';

.paymentSection {
  background-color: #EAF6FD;
  margin: 2rem auto 0;
  padding: 2.5rem;
}

.content {
  width: 550px;
  margin: 0 auto;

  h3 {
    margin-bottom: 1.5rem;
    color: $periwinkle;
    font-size: 1.25rem;
    text-align: center;
    margin-top: 0;
    font-weight: 700;
  }

  input[type=submit], .paymentButton {
    display: block;
    font-size: .875rem;
    background: $periwinkle;
    text-transform: initial;
    font-weight: 400;
    letter-spacing: initial;
    margin: 0 auto;
    cursor: pointer;
    color: white;
    width: 14.5rem;
    height: 2.75rem;
    border-radius: 4px;

    &:disabled {
      opacity: .3;
      cursor: not-allowed;
    }
  }

  p {
    font-size: .75rem;
    margin-bottom: 0;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

  }

}

.cardElementContainer {
  div {
    padding: 1.25rem;
    background-color: white;
    border-radius: .5rem;
    height: 3.75rem;
  }
}
