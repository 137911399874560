@import "Components/global.scss";

.modalTitle {
  color: $secondary-color;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  margin: 0 0 20px;
  text-transform: uppercase;
}
.companyName {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #16346f;
  margin: 40px 0px 16px 16px
}
.newClien{
  margin-block-end: 0;
  padding: 0 16px;
  margin-bottom: -20px;
}
.addContactForm {
  padding-bottom: 1rem;

  input {
    background-color: $white;
  }
  /*
      Separate class because adding !important to
      input's backgroundColor introduces an artifact
      in the county picker
  */
  .whiteBackground {
    background-color: $white !important;
  }

  input[type="submit"] {
    width: 180px;
    align-self: flex-end;
    background-color:  linear-gradient(180deg, #71C6FC 0%, #3F8FC1 100%);
  }
  .save{
    background-color:  linear-gradient(180deg, #71C6FC 0%, #3F8FC1 100%) !important;
  }
  .subBtn{
    text-align: center;
    padding-top: 10px;

  }
}
.efectiveDate>p{
  font-weight: bold;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */

letter-spacing: 0.06em;
text-transform: uppercase;

/* Navy Blue / Base */

color: #16346F;
}

.twoInp {
  width: calc(50% - 10px);
}
.twoInp{
  display: flex;
  width: 100%;
}
.addContactForm > div > div {
  width: 98%;
  margin: 15px 0px 0 16px;
}
.dateInp{
  input{
    background-color: transparent !important;
  }
}
.selectOpt1{
  margin: 10px 15px 10px 0px;
div{
  width: 100% !important;
}
}
.selectOpt2{
  margin: 10px 15px 10px 0px;
div{
  width: 100% !important;
}
}
.twoCol {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.twoCol > div {
  width: calc(50% - 5px);
}
.checkboxCont{
  display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-left: 16px;
}
.check {
  appearance: none;
  outline: none;
  width: 19px;
  height: 19px;
  margin-right: 7px;
  border: 1px solid #707070;
  background-color: #ffffff;
  content: none;
  cursor: pointer;
}

.check:checked {
  background-color: #29b473;
  border-color: #29b473;
}

.check:checked::before {
  content: '';
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 4px;
  margin-left: 5px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}
.fileTemplate,
.fileUploaded {
  color: #707070;
  font-size: 16px;
}

.fileTemplate > .link {
  font-weight: 600;
  color: #707070;
}

.fileUploaded > .link {
  font-weight: 700;
  color: #707070;
  background-color: #d4f8ef;
  padding: 3px 4px;
  letter-spacing: 0.6px;
  text-decoration: underline;
}

.upload {
  width: 75%;
  height: 55px;
  font-size: 16px;
  font-weight: 600;
  color: #707070;
  margin-top: 5px;
  text-transform: uppercase;
  background-image: linear-gradient(to top, #ebeaea 0%, #f8f8f8 100%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.saveContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkmark {
  width: 35px;
  height: 35px;
  background-color: #29b573;
  margin-right: 45px;
  border-radius: 4px;
}

.unsavedCheckmark {
  width: 35px;
  height: 35px;
  background-color: #e4e4e4;
  margin-right: 45px;
  border-radius: 4px;
}

.checkmark::before,
.unsavedCheckmark::before {
  content: "";
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  height: 20px;
  width: 8px;
  margin-left: 11px;
  margin-top: 2px;
  border-bottom: 4px solid #ffffff;
  border-right: 4px solid #ffffff;
}

.save:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(169, 169, 169, 0.493);
}
