@import 'Components/global.scss';

.mainContainer {
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 700;
    text-align: left;
    margin: 2rem 0;
  }

  > button {
    margin-top: 1rem;

    &.imqButton {
      background-color: #5C90D5
    }
  }


  .erSection, .erSectionWarning {
    display: block;
    margin-bottom: 1rem;
    align-items: center;
    padding: 1em;

    h2 {
      font-size: 20px;
      text-align: left;
      margin: 0;
      padding-left: 0.5em;
      font-weight: bold;

      small {
        margin-top: 8px;
        display: block;
        font-weight: normal;
      }
    }
  }

  .erSection {
    background-color: #EDF6FB;

    h2 {
      border-left: 4px solid #c0d0ed;
    }
  }

  .erSectionWarning {
    background-color: caution(10);
    h2 {
      border-left: 4px solid caution(100);
      color: caution(150);
    }
  }


  article {
    width: unset;
    margin-left: unset;
    margin-right: unset;
  }
}

.ancilaryHeading {
  margin-bottom: 30px;

  h1 {
    font-weight: 700;
  }

  .ancilaryHeading {
    text-align: left;
    font-size: 18px;
  }
}

.worksiteTabActive {
  margin-bottom: 0px;
}

.contributionModeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: blue(10);
  padding: 1rem 1.5rem;
  color: navy-blue();
  margin-bottom: 2rem;

  > * {
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.25rem;
    font-family: $zilla;
    text-align: center;
    letter-spacing: .015em;
    font-weight: 600;
  }

  input {
    text-align: center;
    width: 200px;
    margin-right: 0;
  }
}

.contributionModeSwitch {
  height: 58px !important;
  width: 200px !important;
  border-radius: 8px !important;
  border: solid 1px gray() !important;

  button[data-state=unselected] {
    background-color: white !important;
  }
}

.contributionContainer {
  background-color: gray(5);
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr 440px;
  color: navy-blue();
  margin-bottom: 2rem;

  p {
    font-family: $zilla;
    padding-left: 1.25rem;
    border-left: 4px solid periwinkle(30);
    margin-right: 5rem;

    span {
      font-weight: 700
    }
  }
}

.allAncillarySection {
  background: green(25);
  padding: 3rem;
  margin-bottom: 1rem;
}

.aa_cart {
  background-color: white;
  padding: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  border-radius: 4px;

  h2 {
    font-size: 1.5rem;
    font-family: $proxima;
    color: navy-blue();
    margin: 0;
  }

  p {
    font-family: $zilla;

    &.disclaimer {
      font-size: .75rem;
      font-style: italic;
    }

    &.remaining {
      color: periwinkle();
      font-weight: 600;
      font-style: italic;
      margin-bottom: .5em;
    }
  }

  .amount {

    font-size: 3rem;
    font-weight: 600;

    &.ee {
      color: periwinkle()
    }

    &.er {
      color: green()
    }

    span {
      font-size: .5em;
      font-weight: 700;
      line-height: 1.8em;

      &:first-child {
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}

.planTypeInfo {
  font-size: 1rem;
  color: navy-blue();
  background: gray(5);
  padding: 1.25rem;
  margin-bottom: 2rem;

  p {
    font-family: $zilla;
    padding: 0 0 0 1.25rem;
    border-left: 2px solid mint(120);
  }
}

.plansAndFilter {
  max-width: 1366px;
  min-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 40px;
}

.tabsContainer {
  display: flex;
}

.filterBarContainer {
  margin: 1em 1em 1em 2em;
}

.stickyFilters {
  position: sticky;
  top: 150px;
  overflow-y: auto;
  transition: all 0.5s;
}

.planCount {
  text-align: left;
  margin: 1em 0 1em 0;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.filterTitle {
  margin: 1em 0 1em 0;
}

.boldText {
  color: #686868;
  font-weight: 600;
}

hr {
  border: 2px solid #D0D0D0;
}

.titleFlexContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.alignLeft {
  width: 33.33333%;
  text-align: left;
  transition: all 0.5s;
  cursor: pointer;

  .underline {
    text-decoration: underline;
  }

  .triangle {
    font-size: 14px;
  }
}

.alignCenter {
  width: 33.33333%;
  text-align: center;
}

.alignRight {
  width: 33.33333%;
  text-align: right;
}

.plansContainer {
  width: 1131px;
  min-height: 200px;
}

.objectFormat {
  display: flex;
  justify-content: center;
}

.ERtab {
  position: relative;
  background-color: $periwinkle;
  color: white;
  padding: 19px 32px 19px 32px;
  width: 224px;
  height: 60px;
  text-align: center;
  border-radius: 4px 4px 0 0;

  &:not(:last-child) {
    margin-right: 5px;
  }

  font-size: 16px;
  cursor: pointer;

  &.active {
    background-color: white;
    color: $periwinkle;
  }
}

.worksiteTab {
  &.active {
    background-color: #101e38;
    color: white;
  }

  .circleBadge {
    &.active {
      background-color: #305aa7;
      color: white;
    }
  }
}

.EEtab {
  background-color: #29B473;
  color: white;
  padding: 19px 32px 19px 32px;
  width: 224px;
  height: 60px;
  text-align: center;
  border-radius: 4px 4px 0 0;

  &:nth-child(2) {
    margin-left: 5px;
  }

  font-size: 16px;
  cursor: pointer;

  &.active {
    background-color: white;
    color: #29B473;
  }
}

.subTabsContainer {
  z-index: 1;
  margin-bottom: 30px;
  background-color: #101e38;
  padding-top: 15px;
}

.subTabs {
  @include default-content-sizing;
  display: flex;

  p {
    font-weight: 600;
    letter-spacing: 0.02em;
    color: white;
    i {
      font-weight: normal;
    }
  }
}

.worksiteCarriers {
  @include default-content-sizing;
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
}

.worksiteCarrier {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  padding: 1em 1em;
  background-color: white;
  border-radius: 5px;
  box-sizing: content-box;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.5em;
  }

  // &.active {
  //   box-shadow: 5px 5px $periwinkle;
  // }
}

.subTab {
  position: relative;
  padding: 0 1em;
  border-radius: 5px 5px 0 0;
  background-color: $periwinkle;
  color: white;
  width: 250px;
  height: 50px;
  font-weight: 500;
  z-index: 2;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.active {
    z-index: unset;
    color: $periwinkle;
    background-color: white;
  }

}

.proposal_div {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;
  margin-top: -30px;

  p {
    font-family: $zilla;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #16346F;
    width: 666px;
    height: 50px;
  }

  button {
    padding: 17px 28px;
    gap: 10px;
    width: 255px;
    height: 56px;
    background: #48A3DB;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    border-radius: 6px;
    color: #FFFFFF;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

.buttonsContainer {
  display: flex;
}

.circleBadge {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-table;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-left: 3px;
  background-color: $periwinkle-130;
  border-radius: 0 3px 0 3px;
  color: $disabled-gray;

  &.active {
    background-color: $periwinkle;
    color: white;
  }
}

.circleContent {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}

.carrierCheck {
  position: absolute;
  top: calc(50% - 10px);
  left: 10px;
  display: flex;
  justify-content: space-between;
  height: 20px;
  width: 20px;
  margin-right: 5px;

  input[type=checkbox] {
    appearance: none;
    cursor: pointer;
    transition: all 0.25s;
    position: absolute;
    opacity: 0;
  }

  &:hover input[type=checkbox] ~ .radio {
    background-color: $gray-20;
  }

  input[type=checkbox]:checked ~ .radio:after {
    display: block;
  }

  input[type=checkbox]:checked ~ .radio {
    background-color: $periwinkle;
  }

  img {
    margin-right: 0.5em;
    z-index: 2;
  }
}

.radio {
  position: absolute;
  cursor: pointer;
  height: 21px;
  width: 20px;
  background-color: $gray;
  transition: background-color 100ms ease-in;
  border-radius: 3px;
  border: 1px solid $secondary-color;
  content: "√";
  box-shadow: inset 1px 1px 1px black;

  &::after {
    content: "√";
    font-family: Verdana, sans-serif;
    font-size: 1rem;
    position: absolute;
    display: none;
    top: -2px;
    left: 2px;
    color: white;
  }
}

.radioBackground {
  background-color: $gray-20;
}

.modalInfo {
  border: 2px solid #000;
  margin: 0 auto 15px;
  text-align: center;
  padding: 20px;
  font-weight: bold;
  border-radius: 10px;
  background-color: $sky-blue;
  border-color: $light-periwinkle;

  h3 {
    color: $periwinkle;
  }
}

.modalButtons {
  display: flex;
  justify-content: center;

  .confirm, .cancel {
    margin: 8px;
  }
  .cancel {
    background: white;
    color: $periwinkle;
  }
}
