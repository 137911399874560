@import 'Components/global.scss';

.statContainer {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 149px;
  margin: 6px 7px;
  padding: 3px;

  span:first-child {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
  }

  span:last-child {
    text-align: center;
    font-size: .75rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .06rem;
  }

}

.notStarted {
  background: linear-gradient(180deg, #595959 0%, #3C3C3C 100%);

  span { color: white; }
}

.incomplete {
  background: linear-gradient(180deg, #EFEEFA 0%, #D0CDF1 100%);

  span { color: black; }
}

.formFilled {
  background: linear-gradient(#fffae7 0%, #efe6bc 100%);

  span { color: black; }
}

.complete {
  background: linear-gradient(180deg, #E7FFF9 0%, #BCEFE2 100%);

  span { color: black; }
}

.waived {
  background: linear-gradient(180deg, #DAEDF8 0%, #B6DAF1 100%);

  span { color: black; }
}
