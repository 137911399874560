@import "Components/global.scss";

.mainContainer {
  width: 900px;
  margin: 0 auto;
  text-align: center;
}
.benifitEnrolment{
  max-width: 1150px;
  margin: 0 auto;
  padding-left: 40px;
  p {
   font-size: 16px;
   color: #16346F;
   font-weight: 700;
   text-transform: uppercase;
   margin: 0 0 30px 0;
 }
}
.healcareHeading {
  font-weight: 600;
}
.title {
  margin-bottom: 4px;
}

.labelAside {
  color: #89898a;
  font-size: 14px;
}

.inputLabel {
  display: block;
  padding-left: 0;
  margin-bottom: 9px;
  margin-top: 13px;
  color: #1f2e4d;
  font-size: 15px;
  flex: 0 0 100%;
  text-align: left;
}

.formContainer .mainForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  background: white;
  padding: 30px 20px 0 20px;

  fieldset > .eeSec {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .cityGrid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 0.5rem;
  }

  .stateGrid {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    gap: 0.5rem;
  }

  .spacer {
    min-height: 2.5rem;
    text-align: left;
  }

  .measurementsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  input[type="submit"],
  input[type="button"] {
    display: block;
    margin: 0 auto 0;
    width: 100%;
    height: 53px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    background: $blue;
    color: white;
    text-transform: unset;
    font-weight: 600;
    font-size: 0.875rem;
    border-radius: 4px;
    letter-spacing: 0.03rem;
    cursor: pointer;
  }

  input[type="button"] {
    background: #f3f3f3;
    color: $secondary-color;
  }
}
.formFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.waiveButton:hover {
  font-size: 16px;
}

.numericInput {
  padding-right: 10px !important;
}

.bodyMeasurements {
  display: grid;
  grid-template-columns: 35% 25% 35%;
  gap: 10px;
}

.planTypeInfo {
  font-size: 1rem;
  color: navy-blue();
  background: white;
  padding: 1.75rem 1.25rem 0 1.25rem;

  p {
    font-family: $zilla;
    padding: 0 0 0 1.25rem;
    border-left: 2px solid mint(40);
    font-weight: 500;
    text-align: left;
    margin: 0;
  }
}
