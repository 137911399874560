@import 'Components/global.scss';

.licensesForm {
  margin: 0 auto 20px;
  max-width: 800px;
}

.headerStyles {
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 26px;
  left: 0px;
  top: 0px;


  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 auto 20px;

  max-width: 800px;
  white-space: nowrap;

  .headerItem {
    font-family: $proxima;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 115%;
    /* or 18px */
    letter-spacing: 0.035em;
    text-transform: uppercase;
    cursor: pointer;

    /* Gray / 130 */
    color: #686868;

    /* Inside Auto Layout */
    flex: 'grow';
    flex-grow: 0;

    margin: 0 auto;
  }

  .selectedHeaderItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    cursor: pointer;

    position: static;

    /* Inside Auto Layout */
    flex: none;
    flex-grow: 0;
    margin: 0px auto;
  }

  .selectedText {
    font-family: $proxima;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 115%;
    /* or 18px */
    letter-spacing: 0.035em;
    text-transform: uppercase;

    color: $purple;

    /* Inside Auto Layout */
    flex: none;
    flex-grow: 0;
    margin: 0;
  }

  .selectedUnderline {
    position: static;
    width: 100%;

    height: 3px;
    background-color: $purple;

    /* Inside Auto Layout */
    flex: none;
    flex-grow: 0;
    margin: 8px 0px;
  }
}