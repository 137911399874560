@import 'Components/global.scss';

.rightButtons {
  margin-left: auto;
  grid-template-columns: 1fr 1fr;
  display: grid;

  .saveButton {
    height: 3.5rem;
    color: $periwinkle;
    font-size: 16px;
  }
}
