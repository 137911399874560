@import 'Components/global.scss';

.mainContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 1.5rem;
    margin-bottom: 0rem;
    font-weight: 800;
    letter-spacing: .008em;
  }

  h2 {
    font-weight: 600;
    margin-top: .5rem;
  }

  button { margin-top: 1rem }
}

.escape { margin-top: 6rem }
